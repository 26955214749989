import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

import "./Companies/table.scss";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import * as XLSX from "xlsx";
import apiClient from "../api/client";
import { ArrowBack, DownloadSharp } from "@mui/icons-material";

const ApproveSeller = () => {
  const rows = [
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 2235235,
      product: "Playstation 5",
      img: "https://m.media-amazon.com/images/I/31JaiPXYI8L._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Michael Doe",
      date: "1 March",
      amount: 900,
      method: "Online Payment",
      status: "Pending",
    },
    {
      id: 2342353,
      product: "Redragon S101",
      img: "https://m.media-amazon.com/images/I/71kr3WAj1FL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 35,
      method: "Cash on Delivery",
      status: "Pending",
    },
    {
      id: 2357741,
      product: "Razer Blade 15",
      img: "https://m.media-amazon.com/images/I/71wF7YDIQkL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Jane Smith",
      date: "1 March",
      amount: 920,
      method: "Online",
      status: "Approved",
    },
    {
      id: 2342355,
      product: "ASUS ROG Strix",
      img: "https://m.media-amazon.com/images/I/81hH5vK-MCL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Harold Carol",
      date: "1 March",
      amount: 2000,
      method: "Online",
      status: "Pending",
    },
  ];
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVendors();
  }, [currentPageNo, del]);

  const fetchVendors = async () => {
    const { data } = await apiClient.get("/auth/unapproved-seller");

    setGetList(data);
  };

  const handleCheck = async (r) => {
    setIsLoaded(true);
    swal({
      title: "Are you sure?",
      text: `You want to Approve the New vendor - `,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result1 = await apiClient.post("/auth/approve-seller", {id: r._id, sellerType: r.type});
      }
    });
  };

  const handlDeleteById = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.delete("/products/delete", { id });
        if (result.ok) {
          swal("Delete Success");
          setDel(!del);
        }
      }
    });
  };

  const exportDataToExcel = async (ob) => {
    const { data } = await apiClient.get("/products/download");

    let arr = [];
    const flattenObj = (ob) => {
      let result = {};
      for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i]);

          delete temp.brand;
          delete temp.image;
          delete temp.email;
          delete temp.mobileNumber;
          delete temp.region;
          delete temp.zone;
          delete temp._id;

          for (const j in temp) {
            result[i + "." + j] = temp[j];
          }
        } else if (
          !Array.isArray(ob[i]) &&
          i != "updatedAt" &&
          i != "taxPrice" &&
          i != "__v"
        ) {
          if (i === "createdAt") {
            result[i] = new Date(ob[i]).toDateString();
          } else {
            result[i] = ob[i];
          }
        }
      }

      return result;
    };

    for (let i = 0; i < data?.length; i++) {
      const newobj = flattenObj(data[i]);
      console.log(newobj);
      arr.push(newobj);
    }

    setTimeout(() => {
      const data1 = arr;
      const worksheet = XLSX.utils.json_to_sheet(data1, {
        header: [
          "_id",
          "hsnCode",
          "groupId",
          "name",
          "brand",
          "size",
          "color",
          "flavour",
          "category",
          "subcategory",
          "specialcategory",
          "cost_price",
          "sell_price",
          "discount",
          "details",
          "description",
          "image",
          "weight",
          "countInStock",
          "manufacturer",
          "notes",
          "foodType",
          "limit",
          "shelflife",
          "fssai",
          "country",
          "expiry",
        ],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "ProductSheet.xlsx");
    }, 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Grid container my={2} spacing={2}>
            <Grid item xs={9}>
              <h2 className="">All Sellers</h2>
            </Grid>
            <Grid item alignContent={"end"} alignSelf={"end"} xs={1.5}>
              <Button
                onClick={(e) => navigate("/")}
                size="small"
                variant="contained"
                endIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Grid>
            <Grid item alignSelf={"end"} xs={1.5}>
              <Button
                size="small"
                variant="contained"
                endIcon={<DownloadSharp />}
              >
                Download
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">Name</TableCell>
                  <TableCell className="tableCell">Email</TableCell>
                  <TableCell className="tableCell">Phone</TableCell>
                  <TableCell className="tableCell">
                    Registration Number
                  </TableCell>

                  <TableCell className="tableCell">Type</TableCell>
                  <TableCell className="tableCell">Edit</TableCell>
                  <TableCell className="tableCell">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getList?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell className="tableCell">{row.name}</TableCell>

                    <TableCell className="tableCell">{row.email}</TableCell>
                    <TableCell className="tableCell">{row.phone}</TableCell>
                    <TableCell className="tableCell">
                      {row.registrationNumber}
                    </TableCell>
                    <TableCell className="tableCell">{row.type}</TableCell>
                    <TableCell className="tableCell">
                      <Typography
                        className="edit-btn"
                        onClick={(e) => handleCheck(row)}
                      >
                        <i className="fas fa-edit" />
                      </Typography>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Typography
                        className="delete-btn"
                        onClick={(e) => handlDeleteById(row)}
                      >
                        <i className="fas fa-trash" />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default ApproveSeller;
