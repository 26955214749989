import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import swal from "sweetalert";
import apiClient from "../api/client";
import useAuth from "./../auth/useAuth";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="">
//         Tawreed
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Register() {
  const [userType, setUserType] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [uploadVisible, setUploadVisible] = useState(false);
  const navigate = useNavigate();
  const { logIn } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadVisible(true);
    let result;
    switch (userType) {
     
      case "ecom":
        result = await apiClient.post("/auth/ecom-register", {
          name,
          email,
          password,
          phone,
          registrationNumber,
        });
        if (!result.ok) {
          swal("Error Retry!");
        } else {
          swal("Registered Successfully Please wait for the admin to approve");
          navigate("/")
        }
        break;
      case "property":
        result = await apiClient.post("/auth/property-register", {
          name,
          email,
          password,
          phone,
          registrationNumber,
        });
        if (!result.ok) {
          swal("Error Retry!");
        } else {
          swal("Registered Successfully Please wait for the admin to approve");
          navigate("/")
        }
        break;
      case "maintenance":
        result = await apiClient.post("/auth/maintenance-register", {
          name,
          email,
          password,
          phone,
          registrationNumber,
        });
        if (!result.ok) {
          swal("Error Retry!");
        } else {
          swal("Registered Successfully Please wait for the admin to approve");
          navigate("/")
        }
        break;
      case "company":
        result = await apiClient.post("/auth/company-register", {
          name,
          email,
          password,
          phone,
          registrationNumber,
        });
        if (!result.ok) {
          swal("Error Retry!");
        } else {
          swal("Registered Successfully Please wait for the admin to approve");
          navigate("/")
        }
        break;

      default:
        break;
    }
   
  };
  const handleChange1 = (event) => {
    setUserType(event.target.value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
          
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="Name"
                  required
                  fullWidth
                  id="Name"
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email Address"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="tel"
                  id="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="registrationNumber"
                  label="RegistrationNumber"
                  
                  id="registrationNumber"
                  onChange={(e) => setRegistrationNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="simple-select">User Type*</InputLabel>
                <Select
                  labelId="simple-select"
                  id="demo-simple-select"
                  value={userType}
                  required
                  label="User Type"
                  onChange={handleChange1}
                  sx={{ width: "100%" }}
                >
                 
                  <MenuItem value={"ecom"}>Ecom Seller</MenuItem>
                  <MenuItem value={"property"}>Property Manager</MenuItem>
                  <MenuItem value={"maintenance"}>Maintenance Manager</MenuItem>
                  <MenuItem value={"company"}>Company</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
