// import { useState } from "react";
// import { useNavigate } from "react-router-dom";

// import { mobile } from "../responsive";

// import swal from "sweetalert";

// import PropagateLoader from "react-spinners/PropagateLoader";
// import styled from "styled-components";
// import useAuth from "../auth/useAuth";
// import apiClient from "../api/client";

// const Login = () => {
//   const [state, setState] = useState({
//     email: "",
//     password: "",
//   });
//   const [uploadVisible, setUploadVisible] = useState(false);
//   const navigate = useNavigate();
//   const { logIn } = useAuth();

//   function handleChange(event) {
//     const { name, value } = event.target;
//     setState((prevState) => ({ ...prevState, [name]: value }));
//   }

//   const handleSubmit = async (e) => {
//     setUploadVisible(true);
//     e.preventDefault();
//     const result = await apiClient.post("/admin/adminlogin", {
//       email: state.email,
//       password: state.password,
//     });
//     if (result.ok) {
//       setUploadVisible(false);

//       swal("Login Success");
//       logIn(result.data.token);
//       //   navigate("/");
//     } else {
//       setUploadVisible(false);
//       swal("Login Error Retry");
//     }
//   };

import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAuth from "../auth/useAuth";
import apiClient from "../api/client";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LogIn() {
  const [userType, setUserType] = useState("");
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [uploadVisible, setUploadVisible] = useState(false);
  const navigate = useNavigate();
  const { logIn } = useAuth();
  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }
  const handleChange1 = (event) => {
    setUserType(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userType === "") {
      swal("Select User Type");
    } else {
      setUploadVisible(true);
      console.log(userType);
      let result;
      switch (userType) {
        case "ecom":
          result = await apiClient.post("/auth/ecom-login", {
            email: state.email,
            password: state.password,
          });
          if (!result.ok) {
            swal("Error Retry!");
          } else {
            setUploadVisible(false);
            swal("Login Success");
            logIn(result.data.token);
          }
          break;
        case "admin":
          result = await apiClient.post("/auth/admin-login", {
            email: state.email,
            password: state.password,
          });
          if (!result.ok) {
            swal("Error Retry!");
          } else {
            setUploadVisible(false);
            swal("Login Success");
            logIn(result.data.token);
          }
          break;
        case "property":
          result = await apiClient.post("/auth/property-login", {
            email: state.email,
            password: state.password,
          });
          if (!result.ok) {
            swal("Error Retry!");
          } else {
            setUploadVisible(false);
            swal("Login Success");
            logIn(result.data.token);
          }
          break;
        case "maintenance":
          result = await apiClient.post("/auth/maintenance-login", {
            email: state.email,
            password: state.password,
          });
          if (!result.ok) {
            swal("Error Retry!");
          } else {
            setUploadVisible(false);
            swal("Login Success");
            logIn(result.data.token);
          }
          break;
        case "company":
          result = await apiClient.post("/auth/company-login", {
            email: state.email,
            password: state.password,
          });
          if (!result.ok) {
            swal("Error Retry!");
          } else {
            setUploadVisible(false);
            swal("Login Success");
            logIn(result.data.token);
          }
          break;

        default:
          break;
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Box sx={{ mt: 2, minWidth: 220 }}>
              <InputLabel id="simple-select">User Type*</InputLabel>
              <Select
                labelId="simple-select"
                id="demo-simple-select"
                value={userType}
                label="User Type"
                onChange={handleChange1}
                sx={{ width: "100%" }}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"ecom"}>Ecom Seller</MenuItem>
                <MenuItem value={"property"}>Property Manager</MenuItem>
                <MenuItem value={"maintenance"}>Maintenance Manager</MenuItem>
                <MenuItem value={"company"}>Company</MenuItem>
              </Select>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
