import React from "react";
import { Link } from "react-router-dom";
import {
  StoreSharp,
  MapsHomeWorkSharp,
  EngineeringSharp,
  CorporateFareSharp,
  FilterAltSharp,
} from "@mui/icons-material";
import useAuth from "../auth/useAuth";
const SideNavBar = () => {
  const {user} = useAuth();
 
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link className="nav-link active" to="/">
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt" />
              </div>
              Dashboard
            </Link>
            {user?.type === "admin" && (
              <div>
                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapseProducts"
                  aria-expanded="false"
                  aria-controls="collapseProducts"
                >
                  <div className="sb-nav-link-icon">
                    <StoreSharp className="icon" />
                  </div>
                  Products
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapseProducts"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/create"
                    >
                      Add Product
                    </Link>
                    <Link className="nav-link sub_nav_link" to="/product/list">
                      All Products
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/sponsored"
                    >
                      Sponsored
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/vendorList"
                    >
                      All Seller
                    </Link>
                  </nav>
                </div>
                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapseCategories"
                  aria-expanded="false"
                  aria-controls="collapseCategories"
                >
                  <div className="sb-nav-link-icon">
                    <MapsHomeWorkSharp />
                  </div>
                  Property
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapseCategories"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/property/list">
                      All Properties
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/property/vendorList"
                    >
                      All Property Manager
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/property/create"
                    >
                      Add Property
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/property/sponsored"
                    >
                      Add Sponsored
                    </Link>
                  </nav>
                </div>

                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapsemaintenance"
                  aria-expanded="false"
                  aria-controls="collapsemaintenance"
                >
                  <div className="sb-nav-link-icon">
                    <EngineeringSharp />
                  </div>
                  Maintenance
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapsemaintenance"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/category/list">
                      Add Form
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/maintenance/enquiry"
                    >
                      All Enquiries
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/maintenance/manager"
                    >
                      All Manager
                    </Link>
                  </nav>
                </div>

                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapsecompany"
                  aria-expanded="false"
                  aria-controls="collapsecompany"
                >
                  <div className="sb-nav-link-icon">
                    <CorporateFareSharp />
                  </div>
                  Company
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapsecompany"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/company/list">
                      All Companies
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/company/category/list"
                    >
                      All Categories
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/company/category/create"
                    >
                      Add Categories
                    </Link>
                  </nav>
                </div>

                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapsefilter"
                  aria-expanded="false"
                  aria-controls="collapsefilter"
                >
                  <div className="sb-nav-link-icon">
                    <FilterAltSharp />
                  </div>
                  Filter
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapsefilter"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/category/list"
                    >
                      All Ecom Categories
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/brand/list"
                    >
                      All Ecom Brands
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/maintenance/category/list"
                    >
                      All Maintenance Category
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/category/create"
                    >
                      Add Ecom Category
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/brand/create"
                    >
                      Add Ecom Brands
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/maintenance/category/create"
                    >
                      Add Maintenance Category
                    </Link>
                  </nav>
                </div>

                <Link className="nav-link" to="/customer/list">
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-users" />
                  </div>
                  Customers
                </Link>
                <Link className="nav-link" to="/approve">
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-user-tie" />
                  </div>
                  Approve Sellers
                </Link>
                <Link className="nav-link" to="/orders/list">
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-shipping-fast" />
                  </div>
                  Orders
                </Link>

                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapseBannerList"
                  aria-expanded="false"
                  aria-controls="collapseBannerList"
                >
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-images" />
                  </div>
                  Ads
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapseBannerList"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/banners/image">
                      Home Screen
                    </Link>
                  </nav>
                </div>

                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapseRevenueList"
                  aria-expanded="false"
                  aria-controls="collapseRevenueList"
                >
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-images" />
                  </div>
                  Sales
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapseRevenueList"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/revenue">
                      Revenue
                    </Link>
                  </nav>
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/top-customers">
                      Top Customers
                    </Link>
                  </nav>
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/top-products">
                      Top Sellers
                    </Link>
                  </nav>
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link
                      className="nav-link sub_nav_link"
                      to="/create-customer"
                    >
                      Create Customer
                    </Link>
                  </nav>
                </div>
              </div>
            )}
            {user?.type === "ecom" && (
              <div>
                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapseProducts"
                  aria-expanded="false"
                  aria-controls="collapseProducts"
                >
                  <div className="sb-nav-link-icon">
                    <StoreSharp className="icon" />
                  </div>
                  Products
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapseProducts"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/create"
                    >
                      Add Product
                    </Link>
                    <Link className="nav-link sub_nav_link" to="/product/list">
                      All Products
                    </Link>
                  </nav>
                </div>

                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapsefilter"
                  aria-expanded="false"
                  aria-controls="collapsefilter"
                >
                  <div className="sb-nav-link-icon">
                    <FilterAltSharp />
                  </div>
                  Filter
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapsefilter"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/category/list"
                    >
                      All Ecom Categories
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/brand/list"
                    >
                      All Ecom Brands
                    </Link>

                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/category/create"
                    >
                      Add Ecom Category
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/product/brand/create"
                    >
                      Add Ecom Brands
                    </Link>
                  </nav>
                </div>

                <Link className="nav-link" to="/orders/list">
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-shipping-fast" />
                  </div>
                  Orders
                </Link>
              </div>
            )}
            {user.type ==="maintenance" && (<div>
              <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapsemaintenance"
                  aria-expanded="false"
                  aria-controls="collapsemaintenance"
                >
                  <div className="sb-nav-link-icon">
                    <EngineeringSharp />
                  </div>
                  Maintenance
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapsemaintenance"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    
                    <Link
                      className="nav-link sub_nav_link"
                      to="/maintenance/enquiry"
                    >
                      All Enquiries
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/maintenance/set-my-categories"
                    >
                      My Categories
                    </Link>
                    
                    
                  </nav>
                </div>
            </div>)}
            {user.type ==="property" && (<div>
              <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapseCategories"
                  aria-expanded="false"
                  aria-controls="collapseCategories"
                >
                  <div className="sb-nav-link-icon">
                    <MapsHomeWorkSharp />
                  </div>
                  Property
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapseCategories"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/property/list">
                      All Properties
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/property/vendorList"
                    >
                      All Property Manager
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/property/create"
                    >
                      Add Property
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/property/sponsored"
                    >
                      Add Sponsored
                    </Link>
                  </nav>
                </div>
            </div>)}
            {user.type ==="company" && (
              <div>
                <Link
                  className="nav-link collapsed"
                  to="#"
                  data-toggle="collapse"
                  data-target="#collapsecompany"
                  aria-expanded="false"
                  aria-controls="collapsecompany"
                >
                  <div className="sb-nav-link-icon">
                    <CorporateFareSharp />
                  </div>
                  Company
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down" />
                  </div>
                </Link>
                <div
                  className="collapse"
                  id="collapsecompany"
                  aria-labelledby="headingTwo"
                  data-parent="#sidenavAccordion"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    <Link className="nav-link sub_nav_link" to="/company/profile">
                      My Profile
                    </Link>
                    <Link
                      className="nav-link sub_nav_link"
                      to="/company/set-my-categories"
                    >
                      My Categories
                    </Link>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SideNavBar;
