import React, { useState, useEffect, useRef, useMemo } from "react";
// import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Grid,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillFormats } from "../../components/editor/quill";
const EditProperty = () => {
  const locat = useLocation();
  const data = locat.state;

  const quillRef = useRef();
  const [description, setDescription] = useState(data?.description);
  const [details, setDetails] = useState(data?.details);
  const [type, setType] = useState(data?.type);
  const [propertyType, setPropertyType] = useState(data?.propertyType);
  const [rooms, setRooms] = useState(data?.rooms);
  const [bathroom, setBathroom] = useState(data?.bathroom);
  const [status, setStatus] = useState(data?.status);
  const [lang, setLang] = useState(Number(80.9462));
  const [lat, setLat] = useState(Number(26.8467));
  const [location, setLocation] = useState({ lang, lat });

  const [file2, setFile2] = useState([]);
  const [seller, setSeller] = useState();
  const [sellerId, setSellerId] = useState();

  const navigate = useNavigate();

  const [state, setState] = useState({
    sku: data?._id,
    name: data?.name,

    price: data?.price,
    size: data?.size,
  });

  useEffect(() => {
    fetchSellers();
  }, []);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ],
            },
          ],
        ],
      },
    }),
    []
  );

  const fetchSellers = async () => {
    const { data } = await apiClient.get("/property/get-all-sellers");

    setSeller(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Property",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        // const img = await handlesubmit2();

        const result = await apiClient.post("/property/edit", {
          name: state.name,
          id: data?._id,
          // image: img,
          type: type,
          propertyType: propertyType,
          bathroom: bathroom,
          rooms: rooms,
          description: description,
          details: details,
          propertyManager: sellerId,
          status,
          size: status.size,
          price: state.price,
          location: location,
          size: state.size
        });
        if (result.ok) {
          setState({
            name: "",
            image: "",
            price: "",
            size: "",
          });
          swal("Success Property Updated");
          navigate("/property/list");
        }
      }
    });
  };

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const result = await apiClient.post(
        "/upload/uploadMultiple",
        formData,
        config
      );

      if (result.ok) {
        setFile2([]);
        return result.data;
      } else {
        swal("Erro Retry!");
      }
    }
  };

  const seloptions = seller?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });

  const removeImage = async (value) => {
    if (data?.image.length <= 1) {
      swal("Cannot Delete as the product has only 1 image");
    } else {
      const result = await apiClient.delete("/upload/deleteimage", {
        image: value,
      });
      

    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/product/list">Property</a>
            </li>
            <li className="breadcrumb-item active">Edit Property</li>
          </ol>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  size="small"
                  id="Name"
                  label="Title"
                  value={state?.name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="price"
                  required
                  fullWidth
                  type="number"
                  size="small"
                  id="price"
                  value={state?.price}
                  label="Price"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="size"
                  required
                  type="number"
                  fullWidth
                  size="small"
                  id="size"
                  value={state?.size}
                  label="Area Sq feet"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-demo"
                  value={data?.type}
                  options={["Rent", "Sell"]}
                  onChange={(event, newValue) => {
                    setType(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Purpose" />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    "House",
                    "Apartment",
                    "Villa",
                    "Office",
                    "Commercial Building",
                  ]}
                  value={data?.propertyType}
                  onChange={(event, newValue) => {
                    setPropertyType(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Property Type" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
              {seloptions?.length >= 0 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={seloptions}
                    onChange={(event, newValue) => {
                      setSellerId(newValue.value);
                    }}
                    defaultValue={{
                      value: data?.propertyManager?._id,
                      label: data?.propertyManager?.name,
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Seller" />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    "On Hold",
                    "Available",
                    "Booked",
                    "Sold",
                    "Unavailable",
                  ]}
                  value={data?.status}
                  onChange={(event, newValue) => {
                    setStatus(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Status" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="bathroom"
                  required
                  type="number"
                  fullWidth
                  value={bathroom}
                  size="small"
                  id="size"
                  label="Bathroom"
                  onChange={(e) => setBathroom(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="rooms"
                  required
                  type="number"
                  fullWidth
                  value={rooms}
                  size="small"
                  id="size"
                  label="Number of Rooms"
                  onChange={(e) => setRooms(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <Button type="button" variant="contained">
                  Select Location
                </Button>
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Description</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={description}
                  modules={modules}
                  onChange={setDescription}
                  placeholder="Write description..."
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Details</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={details}
                  modules={modules}
                  onChange={setDetails}
                  placeholder="Write Details..."
                />
              </Grid>
            </Grid>

            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={10}>
                <input type="file" multiple onChange={uploadFileHandler} />
              </Grid>
            </Grid>
            <div style={{ display: "flex" }}>
              {data?.image.map((item, i) => {
                return (
                  <div className="prd-img" onClick={() => removeImage(item)}>
                    <img
                      key={i}
                      src={item}
                      alt="product-img"
                      class="update-img"
                    />
                    <div class="middle">
                      <div class="text" style={{ cursor: "pointer" }}>
                        Delete
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Grid sx={4}>
              <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }}>
                Update Property
              </Button>
            </Grid>
          </Box>
        </div>
      </main>
    </div>
  );
};

export default EditProperty;
