import React, { useState, useEffect, useRef, useMemo } from "react";
// import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  BottomNavigationAction,
  Box,
  Fab,
  Grid,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillFormats } from "../../components/editor/quill";
const EditProduct = () => {
  const location = useLocation();
  const data = location.state;

  const quillRef = useRef();
  const [description, setDescription] = useState(data?.description);
  const [details, setDetails] = useState(data?.details);

  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(data?.ecomCategory?._id);

  const [brands, setBrands] = useState();
  const [brandId, setBrandId] = useState();
  const [seller, setSeller] = useState();
  const [sellerId, setSellerId] = useState();

  const navigate = useNavigate();

  const [state, setState] = useState({
    sku: data?._id,
    name: data?.name,

    active: true,
    price: data?.price,
    discount: data?.discount,
    countInStock: data?.countInStock,
  });

  useEffect(() => {
    fetchCategories();
    fetchSellers();
    fetchBrands();
  }, []);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ],
            },
          ],
        ],
      },
    }),
    []
  );

  const fetchBrands = async () => {
    const { data } = await apiClient.get("/ecom/get-brands");
    setBrands(data);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/ecom/get-categories");
    setCat(data);
  };
  const fetchSellers = async () => {
    const { data } = await apiClient.get("/ecom/get-all-sellers");

    setSeller(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const img = await handlesubmit2();

        const result = await apiClient.post("/ecom/edit-product", {
          name: state.name,
          id: state.sku,
          image: img,
          ecombrand: brandId,
          ecomCategory: catId,
          manufacturer: state.manufacturer,
          description: description,
          details: details,
          seller: sellerId,
          price: state.price,
          discount: state.discount,
          countInStock: state.countInStock,
          active: state.active,
        });
        if (result.ok) {
          setState({
            sku: "",
            name: "",
            image: "",
            seller: "",
            sellerId: "",
            description: "",
            details: "",
            active: true,
            price: "",
            discount: "",
            countInStock: "",
          });
          swal("Success product added");
          navigate("/product/list");
        }
      }
    });
  };

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const result = await apiClient.post(
        "/upload/uploadMultiple",
        formData,
        config
      );

      if (result.ok) {
        setFile2([]);
        return result.data;
      } else {
        swal("Error Retry!");
      }
    }
  };

  const brandoptions = brands?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });

  const catoptions = cat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const seloptions = seller?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });

  const removeImage = async (value) => {
    if (data?.image.length <= 1) {
      swal("Cannot Delete as the product has only 1 image");
    } else {
      const result = await apiClient.delete("/upload/deleteimage", {
        image: value,
      });
      

    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Products</a>
            </li>
            <li className="breadcrumb-item active">Add Product</li>
          </ol>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  size="small"
                  id="Name"
                  label="Name"
                  value={state.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="sku"
                  required
                  fullWidth
                  size="small"
                  id="sku"
                  label="SKU"
                  value={state.sku}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="manufacturer"
                  required
                  fullWidth
                  size="small"
                  id="manufacturer"
                  value={state.manufacturer}
                  label="Manufacturer"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="price"
                  required
                  fullWidth
                  type="number"
                  size="small"
                  id="price"
                  label="Price"
                  value={state.price}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="discount"
                  required
                  type="number"
                  fullWidth
                  size="small"
                  id="discount"
                  value={state.discount}
                  label="Discount"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="countInStock"
                  required
                  type="number"
                  fullWidth
                  size="small"
                  id="countInStock"
                  value={state.countInStock}
                  label="Stock"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={4}>
                {seloptions?.length >= 0 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={seloptions}
                    onChange={(event, newValue) => {
                      setSellerId(newValue.value);
                    }}
                    defaultValue={{
                      value: data?.seller?._id,
                      label: data?.seller?.name,
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Seller" />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {brandoptions?.length >= 0 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={brandoptions}
                    onChange={(event, newValue) => {
                      setBrandId(newValue.value);
                    }}
                    defaultValue={{
                      value: data?.ecomBrand?._id,
                      label: data?.ecomBrand?.name,
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Brands" />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {catoptions?.length >= 0 && (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setCatId(newValue.value);
                    }}
                    defaultValue={{
                      value: data?.ecomCategory?._id,
                      label: data?.ecomCategory?.name,
                    }}
                    required
                    id="combo-box-demo"
                    options={catoptions}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Categories" />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Description</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={description}
                  modules={modules}
                  onChange={setDescription}
                  placeholder="Write description..."
                />
              </Grid>
            </Grid>
            <Grid container my={1.5} spacing={2}>
              <Grid item xs={10}>
                <InputLabel sx={{ fontWeight: 600 }}>Details</InputLabel>
                <ReactQuill
                  theme="snow"
                  formats={QuillFormats}
                  ref={quillRef}
                  value={details}
                  modules={modules}
                  onChange={setDetails}
                  placeholder="Write Details..."
                />
              </Grid>
            </Grid>

            <Grid container mt={2.5} spacing={2}>
              <Grid item xs={10}>
                <input type="file" multiple onChange={uploadFileHandler} />
              </Grid>
            </Grid>
            <div style={{ display: "flex" }}>
              {data?.image.map((item, i) => {
                return (
                  <div className="prd-img" onClick={() => removeImage(item)}>
                    <img
                      key={i}
                      src={item}
                      alt="product-img"
                      class="update-img"
                    />
                    <div class="middle">
                      <div class="text" style={{ cursor: "pointer" }}>
                        Delete
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Grid sx={4}>
              <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }}>
                Update Product
              </Button>
            </Grid>
          </Box>
        </div>
      </main>
    </div>
  );
};

export default EditProduct;
