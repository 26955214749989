import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import "./table.scss";
import swal from "sweetalert";

import { ArrowBack, DownloadSharp } from "@mui/icons-material";

const AllManager = () => {

  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

 
  

  useEffect(() => {
    fetchSellers();
  
   
  }, [currentPageNo, del]);

  

  const fetchSellers = async () => {
    const { data } = await apiClient.get("/property/get-sellers");
    console.log(data.sellers)
    setGetList(data.sellers);
  };



  const handlDeleteById = async (id) => {

    swal({
      title: "Are you sure?",
      text: "You want to Delete Seller and all associated properties?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
     
      if (success) {
        const result = await apiClient.delete("/property/delete-sellers", { id });
        if (result.ok) {
          swal("Delete Success");
          setDel(!del);
        }
      }
    });
  };

 

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Grid container my={2} spacing={2}>
            <Grid item xs={10}>
              <h2 className="">All Property Manager</h2>
            </Grid>
            <Grid item  alignSelf={"end"} xs={1.5}>
            <Button onClick={(e) => navigate("/")}
                size="small"
                variant="contained"
                endIcon={<ArrowBack />}
              >
                Back
              </Button>
             
            </Grid>
          
          </Grid>

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">ID</TableCell>
                  <TableCell className="tableCell">Name</TableCell>
            
                  <TableCell className="tableCell">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">{row._id}</TableCell>
                    <TableCell className="tableCell">
                      <div className="cellWrapper">
                        <img src={row.image} alt="" className="image" />
                        {row.name}
                      </div>
                    </TableCell>
                   
                    
                   
                    <TableCell className="tableCell">
                    <Typography
                        className="edit-btn"
                        onClick={(e) =>handlDeleteById(row._id)}
                      >
                        <i className="fas fa-trash" />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllManager