import React, { useEffect, useState } from "react";
import { toCommas } from "../../utils";
import styles from "./Dashboard.module.css";
// import { useHistory, useLocation } from "react-router-dom";
import Empty from "../svgIcons/Empty";
import Chart from "./Chart";
// import Donut from './Donut'
import moment from "moment";
import { Check, Pie, Bag, Card, Clock, Frown } from "./Icons";
import Spinner from "../Spinner/Spinner";
import apiClient from "./../../api/client";
import { startOfWeek, endOfWeek, startOfYear, endOfYear } from "date-fns";
import Widget from "../../components/widget/Widget";
import List from "../../components/table/Table";

const Dashboard = () => {
  // const history = useHistory();

  // const user = JSON.parse(localStorage.getItem("profile"));
  // const { invoices, isLoading } = [];
  // // const unpaid = invoices?.filter((invoice) => (invoice.status === 'Unpaid') || (invoice.status === 'Partial'))
  // const overDue = invoices?.filter(
  //   (invoice) => invoice.dueDate <= new Date().toISOString()
  // );
  const [weeklySales, setWeeklySales] = useState([]);
  const [yearlySales, setYearlySales] = useState([]);
  const [pendingOrder, setPendingOrder] = useState();
  useEffect(() => {
    fetchWeeklyOrders();
    getPendingOrders();
    getYearlySales();
  }, []);

  const getPendingOrders = async () => {
    const { data } = await apiClient.get("/orders/getPendingOrders");
    setPendingOrder(data);
  };

  const getYearlySales = async () => {
    const start = startOfYear(new Date());
    const end = endOfYear(new Date());

    const { data } = await apiClient.get("/orders/getsalesdaterange", {
      startDate: start,
      endDate: end,
    });

    setYearlySales(data?.monthlySales);
  };
  const fetchWeeklyOrders = async () => {
    const start = startOfWeek(new Date());
    const end = endOfWeek(new Date());

    const { data } = await apiClient.get("/orders/getsalesdaterange", {
      startDate: start,
      endDate: end,
    });

    setWeeklySales(data?.monthlySales);
  };
  var total = 0;
  weeklySales?.map((order) => {
    order.orderItems.map((item) => {
      total = total + item?.price * item?.qty;
    });
  });

  // var totalOrderPending = 0;
  // weeklySales?.map((order) => {
  //   if (
  //     order.deliveryStatus !== "Delivered" ||
  //     order.deliveryStatus !== "Cancelled"
  //   ) {
  //   }
  // });

  var totalProfit = 0;

  weeklySales?.map((order) => {
    order.orderItems.map((item) => {
      if (item.product?.cost_price) {
        totalProfit =
          totalProfit +
          (Number(item?.price) - Number(item?.product?.cost_price)) *
            Number(item?.qty);
      }
    });
  });

  return (
    <div id="layoutSidenav_content">
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
        {/* <div className="widgets"> */}
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        {/* </div> */}
          {/* <li
            className={styles.listItem}
            style={{ backgroundColor: "#1976d2", color: "white" }}
          >
            <div>
              <p>{toCommas(total.toFixed(2))} ₹</p>
              <h2 style={{ color: "white" }}>Weekly Sales</h2>
            </div>
            <div>
              <Check />
            </div>
          </li>

          <li className={styles.listItem}>
            <div>
              <p>{toCommas(totalProfit.toFixed(2))} ₹</p>
              <h2>Weekly Profit</h2>
            </div>
            <div>
              <Pie />
            </div>
          </li>

          <li className={styles.listItem}>
            <div>
              <p>{weeklySales?.length}</p>
              <h2>Orders Delivered</h2>
            </div>
            <div>
              <Bag />
            </div>
          </li>

          <li className={styles.listItem}>
            <div>
              <p>{pendingOrder}</p>
              <h2>Order Pending</h2>
            </div>
            <div>
              <Card />
            </div>
          </li> */}
        </ul>
      </section>

      <section>
        <Chart paymentHistory={yearlySales} />
      </section>

      <section>
        <h2 style={{ textAlign: "center", padding: "30px" }}>Recent orders</h2>
        <div>
          <div className={styles.table}>
            {/* <table>
              <tbody>
                {weeklySales?.length !== 0 && (
                  <tr>
                    <th style={{ padding: "15px" }}>#orderId</th>
                    <th style={{ padding: "15px" }}>Customer Name</th>
                    <th style={{ padding: "15px" }}>Payment Method</th>
                    <th style={{ padding: "15px" }}>Date</th>
                    <th style={{ padding: "15px" }}>Status</th>
                    <th style={{ padding: "15px" }}>Slot</th>
                    <th style={{ padding: "15px" }}>Total Amount</th>
                  </tr>
                )}

                {weeklySales?.map((record) => (
                  <tr className={styles.tableRow} key={record._id}>
                    <td>{record._id}</td>
                    <td>{record.user?.name}</td>
                    <td>{record.paymentMethod}</td>
                    <td>{new Date(record.updatedAt).toDateString()}</td>
                    {record.deliveryStatus === "Processing" && (
                      <td
                        style={{
                          backgroundColor: "yellow",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Delivered" && (
                      <td
                        style={{
                          backgroundColor: "#50C878",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Cancelled" && (
                      <td
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Out for Delivery" && (
                      <td
                        style={{
                          backgroundColor: "#0096FF",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    <td>{record?.deliverySlot}</td>
                    <td>₹ {record?.totalPrice} </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
            <List />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
