import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import swal from "sweetalert";
import { Search } from "@mui/icons-material";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
} from "@mui/material";

const AddSponsored = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    handleSearch();
  }, [currentPageNo, del]);

  const handleKey = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const totalPages = Math.ceil(getList?.length / pageSize);
  const lastIndex = pageSize * currentPageNo;
  const firstIndex = lastIndex - pageSize;

  const handleSearch = async () => {
    if (search !== "") {
      const { data } = await apiClient.get("/products/search", {
        Query: search,
        pageNumber: currentPageNo,
      });
      // console.log(data);
      setGetList(data);
      setData(data?.slice(firstIndex, lastIndex));
    }
  };

  const handleaddtoorderagain = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Add ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setIsLoaded(true);
        const { data } = await apiClient.post("/orderAgain/create", {
          id,
        });

        setIsLoaded(false);
        navigate("/order-again");
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Products</li>
          </ol>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <input
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => handleKey(e)}
              />
              <Search
                style={{ color: "gray", fontSize: 26, marginRight: "10px" }}
                onClick={handleSearch}
              />
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>Searched Products</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  {/* <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>No.</th>
                        <th style={{ width: 100 }}>Image</th>
                        <th>Name</th>
                     

                        <th>Stock</th>

                        <th>Order Again</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && data?.length > 0
                        ? data?.map((row, index) => {
                            const imgsrc = `https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/${row?._id}.png`;

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="cate-img-5">
                                    <img src={imgsrc} alt="product-name" />
                                  </div>
                                </td>
                                <td>{row?.name}</td>

                              

                                <td>{row?.countInStock}</td>

                                <td>
                                  <Typography
                                    className="delete-btn"
                                    onClick={(e) =>
                                      handleaddtoorderagain(row._id)
                                    }
                                  >
                                    <i className="fas fa-edit" />
                                  </Typography>
                                </td>

                                <td></td>
                              </tr>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table> */}
                    <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">SKU</TableCell>
                  <TableCell className="tableCell">Product</TableCell>
                  <TableCell className="tableCell">Category</TableCell>
                  <TableCell className="tableCell">
                    Seller
                  </TableCell>

                  <TableCell className="tableCell">Add</TableCell>
                  <TableCell className="tableCell">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">{row.id}</TableCell>
                    <TableCell className="tableCell">
                      <div className="cellWrapper">
                        <img src={row.img} alt="" className="image" />
                        {row.product}
                      </div>
                    </TableCell>
                    <TableCell className="tableCell">{row.customer}</TableCell>
                    <TableCell className="tableCell">{row.date}</TableCell>
                    <TableCell className="tableCell">{row.method}</TableCell>
                    <TableCell className="tableCell">
                      <span className={`status ${row.status}`}>
                        {row.status}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={totalPages}
            page={currentPageNo}
            siblingCount={1}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AddSponsored;
